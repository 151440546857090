import React from 'react';
import Checkbox from '/src/components/ui/checkbox/checkbox';
import { Ul, Li } from '/src/components/styled';
import PropTypes from 'prop-types';

// Радиокнопка
function CheckboxList({
  selectValues, // массив выбранных значений
  labelComponent, // Компонент для отображения label
  options, // массив с объектами для выбора {title: заголовок, value: значение}
  name, // имя
  onChange, // событие при изменении
  isGridList,
  onClickLabel = () => {}
}) {
  const handleChange = (value) => {
    const newValue = [...selectValues];
    const indexValue = newValue.indexOf(value);
    if (indexValue !== -1) {
      newValue.splice(indexValue, 1);
    } else {
      newValue.push(value);
    }
    onChange && onChange(newValue);
  };

  return (
    <Ul $isGridList={isGridList}>
      {options.map((option, index) => (
        <Li key={option.value}>
          <Checkbox
            labelComponent={labelComponent}
            $selectValues={selectValues}
            isChecked={selectValues.includes(option.value)}
            name={name}
            value={option.value}
            text={option.title}
            onClick={(value) => onClickLabel(value, index)}
            onChange={handleChange}
          />
        </Li>
      ))}
    </Ul>
  );
}

CheckboxList.propTypes = {
  selectValues: PropTypes.array,
  labelComponent: PropTypes.any,
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isGridList: PropTypes.bool,
  onClickLabel: PropTypes.func
};

export default CheckboxList;
