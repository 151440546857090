import React from 'react';
import { Label, VisuallyHiddenInput } from '/src/components/styled';
import PropTypes from 'prop-types';

// чекбокс
function Checkbox({
  onClick,
  labelComponent, // Компонент для отображения label
  isChecked, // выбранно ли значение
  name, // имя
  value, // значение
  text, // текст элемента
  onChange, // событие при изменении
  ...props
}) {
  const LabelComponent = labelComponent;

  return (
    <Label>
      <VisuallyHiddenInput
        value={value}
        checked={isChecked}
        name={name}
        onChange={() => onChange(value)}
        {...props}
        type="checkbox"
      />
      <LabelComponent onClick={() => onClick(value)} $isChecked={isChecked}>
        {text}
      </LabelComponent>
    </Label>
  );
}

Checkbox.propTypes = {
  labelComponent: PropTypes.any,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.number,
  text: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
