import React, { useState } from 'react';
import { TabButton, Header, Content, TabListItem } from './styles';
import PropTypes from 'prop-types';

function Tabs({ tabsList = [], maxContentHeiht }) {
  const [selectIndex, setSelectIndex] = useState(0);

  return (
    <div>
      <Header>
        {tabsList.map((tab, index) => (
          <TabListItem key={`tab${index * 10}`}>
            <TabButton
              $isSelect={selectIndex === index}
              {...(selectIndex === index
                ? { as: 'span' }
                : {
                  onClick: () => {
                    setSelectIndex(index);
                  }
                })}
            >
              {tab.title}
            </TabButton>
          </TabListItem>
        ))}
      </Header>
      <Content $maxContentHeiht={maxContentHeiht}>
        {tabsList[selectIndex].content}
      </Content>
    </div>
  );
}

Tabs.propTypes = {
  tabsList: PropTypes.array,
  maxContentHeiht: PropTypes.string
};

export default Tabs;
