import React from 'react';
import About from '/src/components/blocks/about/about';
import FeaturesList from '/src/components/blocks/features-list/features-list';
import PropTypes from 'prop-types';

function MainPage({ features }) {
  return (
    <>
      <About />
      <FeaturesList features={features} />
    </>
  );
}

MainPage.propTypes = {
  features: PropTypes.array,
};

export default MainPage;
