const features = [
  {
    id: 0,
    title: 'Еда намного вкуснее',
    owner: 'Фермерские продукты',
    isNegative: false,
    image: '/images/features/eat.png',
    about:
      'Домашняя колбаса из&nbsp;мяса, соли и&nbsp;специй и&nbsp;колбаса из&nbsp;магазина&nbsp;&mdash; два настолько разных продукта, что они даже не&nbsp;родственники'
  },
  {
    id: 1,
    title: 'Просроченные продукты',
    owner: 'Магазинные продукты',
    isNegative: true,
    image: '/images/features/garbage.png',
    about:
      'Из-за большого количества товара сотрудники магазинов не&nbsp;успевают своевременно производить замену товара'
  },
  {
    id: 3,
    title: 'Натуральные продукты',
    owner: 'Фермерские продукты',
    isNegative: false,
    image: '/images/features/sprout.png',
    about:
      'Поставляем местные органические продукты, выращенные без пестицидов и&nbsp;химических удобрений.'
  },
  {
    id: 4,
    title: 'Некачественное мясо',
    owner: 'Магазинные продукты',
    isNegative: true,
    image: '/images/features/no-meat.png',
    about:
      'Мясные полуфабрикаты, в&nbsp;которых содержится чрезмерно много натрия, вредных жиров, консервантов'
  }
];

export default features;
